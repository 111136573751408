import './footerBlock.css';
import instagram from '../../assets/images/instagram.png';
import logo from '../../assets/images/services/logo.jpg';

export const FooterBlock = () => {
  return (
    <div className="footer">
      <div className="footer_block">
        <div>
          <img src={logo} alt="logo" className="footer_logo" />
        </div>
        <div>
          <div>Номер телефона: +7 (777) 646 33 33</div>
          <div>Адрес: г. Алматы, ул. Пушкина 25.</div>
          <div>Время работы: 9:00 - 20:00</div>
          <div>Email: info@kelin-clean.kz</div>
          <div className="instagram">
            <a
              href="https://www.instagram.com/kelin_clean.kz/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="kelin_clean instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
